//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SpIndexFloor',
  props: {
    title: {
      type: String,
      default: ''
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    // 跳转到详情
    toGoodsDetail(id) {
      this.$router.push({
        path: '/goods/detail',
        query: {
          id
        }
      })
    },
    toBrand(id) {
      this.$router.push({
        path: '/goods/search',
        query: {
          brand: id
        }
      })
    },
    toGoodsList(id) {
      this.$router.push({
        path: '/goods/list',
        query: {
          id
        }
      })
    }
  }
}
